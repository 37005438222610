<template>
    <div class="logo--wrap">

      <h2 id="logo" class="logo"><a alt="FusionRift Studio" href="https://eve-online.fusionrift.studio/">
        <img class="logoimg" src="@/assets/tiny-logo_plain-white.png" alt="Simplified FusionRift Logo Image">
        <span class="fusion">FUSION</span>
        <span class="divider"> | </span>
        <span class="rift">RIFT</span>
        <span class="divider"> • </span>
        <span class="studio">STUDIO</span>
      </a></h2>

    </div>
</template>

<style lang="scss">
@mixin tiny {
  @media only screen and (width < 400px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}

.logo,
#logo {

    &--wrap { 
      margin-inline-end: auto; 
      @include mobile { margin-inline: auto; }
      // @include tiny { margin-inline-start: 0; }
    }

    font-family: var(--header_font);
    a {
      display: flex;
      align-items: center;
      gap: .5rem;
      color: var(--font_color); 
      text-decoration: none;
      user-select: none;
    }
    
    span {font-family: inherit;}
    .logoimg { height: 1em; aspect-ratio: 1; margin-inline-end: .25rem; }
    .fusion {font-weight: 800; letter-spacing: -0.05em;}
    .rift {font-weight: 500; letter-spacing: .1em;}
    .studio {font-weight: 100; letter-spacing: .3em; }
    .divider {
        font-weight: 100;
        line-height: 200%;
        transform: scale(1.3) translateY(-.1rem);

        &:nth-of-type(4) {
            margin-inline-start: .5rem;
            margin-inline-end: .6rem;
            transform: scale(2) translateY(-.1rem);
        }
    }
      
      @include mobile {
        .divider,
        .studio {
          display: none;
        }
        .rift {
          font-weight: 200;
        }
      }
    // @include tiny {
    //   .fusion,
    //   .rift {
    //     display: none;
    //   }
    // }
  
}

</style>