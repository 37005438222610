<template>
    <div class="nav__item--wrapper" :class="{'open': mobile}">

      <button id="mobile-menu" role="navigation" title="Access Navigation Menu" @click.prevent="mobile = !mobile">
        <img src="@/assets/icon/mobile.svg" alt="menu icon" aria-hidden="true">
        <!-- <img src="@/assets/icon/mobile.svg" alt="menu icon" aria-hidden="true" class="secondary"> -->
        <img src="@/assets/icon/error.svg" alt="menu icon" aria-hidden="true" class="close">
      </button>
            
      <div v-for="(tab, index) in navi" :key="index" class="nav__item--container" role="navigation">
        
            <a v-if="tab.outgoing" class="nav__item"
                :href="tab.link" target="_blank" rel="noopener noreferrer">
                <span>
                  {{ tab.name }}
                  <img class="nav__item--icon" aria-hidden="true" :src="getIcon('arrow')">
                </span>
            </a>

            <router-link v-else-if="!tab.list"
                :to="{ name: tab.slug }" class="nav__item">
                {{ tab.name }}
            </router-link>

            <div v-else class="nav__item nav__item--list">
                <span @click.prevent="submenu[index] = !submenu[index]">
                  {{ tab.name }}
                  <img class="nav__item--icon arrow" aria-hidden="true" :src="getIcon(tab.icon)" :class="{'turn': submenu[index]}">
                </span>

                <div class="submenu" :class="{'show': submenu[index]}">
                      <div v-for="(item, slug) in tab.list" :key="slug" class="submenu__item--container">
                        
                          <a v-if="item.outgoing" class="submenu__item" alt="Outgoing Link"
                              :href="item.link" target="_blank" rel="noopener noreferrer">
                              <img v-if="item.icon" class="nav__item--icon" aria-hidden="true" :src="getIcon(item.icon)">
                              {{ item.name }}
                              <img v-if="item.icon" class="nav__item--icon" aria-hidden="true" :src="getIcon('outgoing')">
                          </a>

                          <router-link v-else-if="!item.list"
                              :to="{ name: item.slug }" class="submenu__item">
                              <!-- <img class="nav__item--icon" aria-hidden="true" :src="getIcon(item.icon)"> -->
                              {{ item.name }}
                              <!-- :to="{ name: item.slug }" -->
                          </router-link>

                          <div v-else class="submenu__item submenu__item--list">
                            <!-- <img class="nav__item--icon" aria-hidden="true" :src="getIcon(item.icon)"> -->
                            {{ tab.name }}
                          </div>
                        
                      </div>
                </div>

            </div>
        
      </div>

        <!-- 
        <router-link :to="{ name: 'home' }" class="nav__menu--item nav__menu--link">
            <img class="nav__menu--img" alt="Overview Navigation Link Icon" src="@/assets/icon/overview.svg">
            <span class="nav__menu--text">About</span>
        </router-link>

        <router-link :to="{ name: 'moons' }" class="nav__menu--item nav__menu--link">
            <img class="nav__menu--img" alt="Moon Atlas Navigation Link Icon" src="@/assets/icon/moon_circle.svg">
            <span class="nav__menu--text">Moon Atlas</span>
        </router-link>

        <router-link :to="{ name: 'planets' }" class="nav__menu--item nav__menu--link">
            <img class="nav__menu--img" alt="Planetary Interaction Link Icon" src="@/assets/icon/planet_circle.svg">
            <span class="nav__menu--text">PI Helper</span>
        </router-link> 
      -->


    </div>

</template>

<script>
export default {
  name: 'Navigation',
  components: {
    
  },
  data() {
    return {
        mobile: false, submenu: [],
        navi: [
          { 
            slug: 'home', 
            name: 'About',
            icon: 'overview',
            list: false,
            outgoing: false
          },
          { 
            slug: 'tools',
            name: 'Tools',
            icon: 'list',
            outgoing: false,
            list: [
              { slug: 'moons', name: 'Moon Atlas', icon: '', outgoing: false },
              { slug: 'planets', name: 'Planet Helper', icon: '', outgoing: false },
              { slug: 'logos', name: 'Alliance Logos', icon: '', outgoing: false },
              { slug: 'guides', name: 'Guides & Articles', icon: '', outgoing: false },
            ]
          },
          { 
            slug: 'info',
            name: 'Info',
            icon: 'list',
            outgoing: false,
            list: [
              { slug: 'discord', name: 'Join Discord Server', icon: 'discord-mark-white', outgoing: true, link:'https://discord.gg/3km5UFkcFP' },
              { slug: 'contact', name: 'Contact', icon: '', outgoing: false },
              { slug: 'updates', name: 'Updates', icon: '', outgoing: false },
              { slug: 'roadmap', name: 'Roadmap', icon: '', outgoing: false },
              { slug: 'faq', name: 'F.A.Q.', icon: '', outgoing: false },
              { slug: 'impressum', name: 'Impressum', icon: '', outgoing: false },
            ]
          },
          { 
            slug: 'more',
            name: 'Explore',
            icon: 'plus',
            outgoing: true,
            list: false,
            link: 'https://www.fusionrift.studio/'
          }
        ]
    }
  },
  methods: {
    getIcon(icon) {
      if (!icon) return require(`@/assets/icon/star.svg`)
      return require(`@/assets/icon/${icon}.svg`)
    }
  }
};
</script>

<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 990px) {
    @content;
  }
}

#mobile-menu {
  display: none; visibility: hidden;
  
  background: transparent;
  border-color: transparent;
  border-radius: .25rem;

  @include tablet {
    display: grid; visibility: visible;
    place-items: center;
    min-height: var(--nav-height);
  }

  img { 
    height: 1.5rem; 
    grid-column: 1; grid-row: 1;
    filter: brightness(1);
    z-index: 5;
    transition: transform 400ms ease-in-out, filter 450ms ease-in-out 150ms; 
  }
  .close { filter: brightness(0); z-index: 4; }
  &:focus-visible { outline: none; border-color: var(--font_color); }
}


.nav__item {
    &--wrapper { 
      display: flex; gap: 1rem; background: var(--base_100); padding-inline-start: 3rem; 
      transition: height 450ms ease-in-out, max-width 250ms ease 500ms;
      @include tablet { 
        flex-direction: column; align-items: flex-end;
        position: absolute; top: 0; right: 0; 
        padding: 0;
        height: var(--nav-height); width: 100vw; max-width: 4rem;
        overflow: hidden; 

        &.open { 
          height: 100dvh; max-width: 200vw; 
          transition: height 450ms ease-in-out;

          .nav__item--container { transform: translateX(0); }

          #mobile-menu img { 
            filter: brightness(0);
            transform: rotate(180deg); 
            &.secondary { transform: rotate(45deg); }
            &.close { transform: rotate(180deg); filter: brightness(1); z-index: 6; }
          }

        }

      }
    }
    &--container { 
      display: grid; place-items: center;
      transition: transform 475ms ease-in-out;
      @include tablet { transform: translateX(100dvw); }
    }
    &--icon { height: .8rem; aspect-ratio: 1; transition: transform 350ms ease-in-out; }
    &--list { 
      position: relative; 
      cursor: context-menu; 
      span { display: flex; align-items: center; gap: .5rem; }
      @include tablet { 
        align-items: flex-end;
        .arrow { transform: rotate(-90deg); }
        .turn { transform: rotateX(180deg) rotate(180deg); }
      }
    }
    
    display: flex;
    align-items: center;
    gap: .4rem;
    height: var(--nav-height);
    padding: .5rem;
    white-space: nowrap;
    transition: background 350ms ease;

    @include tablet {
      flex-direction: column;
      height: unset;
    }

    &--list, .submenu__item,
    &:link, &:visited, &:hover, &:active { 
      // background: var(--base_100);
      font-family: var(--font);
      font-weight: 200;
      text-transform: uppercase;
      color: var(--font_color);
      text-decoration: none;
      &:focus-visible, &:focus-within {
        text-decoration: 2px solid var(--font_color);
        text-underline-offset: .25rem;
      }
    }
    &:hover { 
      background: var(--base_300); 
      @include tablet { background: var(--base_100); }
    }
    &:hover .submenu, .submenu:hover,
    .submenu:focus-within { 
      transform: translateY(0); 
      // @include tablet { max-height: 50vh; }
    }

}

.submenu {
  z-index: -10;
  position: absolute; 
  top: var(--nav-height);
  right: -2.5rem;
  transform: translateY(-50rem);

  display: grid;
  gap: 1rem;
  padding: 1rem 2rem 2rem;
  background: var(--base_300);
  min-width: 150%;

  transition: transform 485ms ease-in-out, 
              background 350ms ease, 
              max-height 800ms ease-out;

  @include tablet {
    z-index: initial;
    position: relative; top: initial; right: initial;
    transform: none;
    gap: 0; padding: 0; min-width: 0;

    width: calc(100vw - 1rem);
    border-radius: .25rem;

    overflow: hidden;
    max-height: 0;
    &.show { max-height: 50vh; }
  }

  &__item{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    padding: .5rem 1rem;
    border-radius: .25rem;
    transition: background 450ms ease-out;
    &:hover, &:focus-visible { 
      background: var(--base_100); 
    }
  }
}

</style>

