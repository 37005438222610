<template>
    
    <!-- <h4>Frequently Asked Questions</h4> -->
    <p>
      A couple questions that were asked frequently.
    </p>

    <div class="accordion">
      <div class="accordion__item" v-for="(item, index) in items" :key="index">

        <button class="accordion__button" @click="toggle(index)" :class="{shown: item.show}">
          {{ item.question }}  <span class="accordion__button--indicator"> + </span>
        </button>

        <div class="accordion__content" :class="{'show': item.show}">
          <p class="accordion__content--text">{{ item.answer }}</p>
        </div>

      </div>
    </div>
</template>
  
<script>
  export default {
    data() {
      return {
        items: [
          {
            question: "Where did you get this website from?",
            answer: "I wrote and designed this website myself using the Vue 3 Javascript framework \
            and open source softwares including Inkscape, Krita and Figma. Some graphics are \
            sourced from ingame screenshots, some crafted with AI tools. All vector graphics and icons \
            however are designed and made from scratch by myself.If you're interested in getting  \
            your own unique website custom made for you, get in touch with me."
          },
          {
            question: "Why do you scan so many moons?",
            answer: "I like to scan moons."
          },
          {
            question: "The data provided is free?",
            answer: "Yes, free data for ultimate chaos. Every moon exposed. \
            I do appreciate ingame donations however so I can scan more moons. Send \
            ISK to Lucky Drop Shipyards and I double my efforts."
          },
          {
            question: "Can I help you scan moons?",
            answer: "Unfortunately there is no way to verify if moon scan data \
            is complete, correct and troll-free without re-scanning the whole system. \
            If you still want to contribute, I would much appreciate a donation of Gaze \
            Survey Scan Probes, ISK to buy those, or materials to build them. If you'd \
            like to donate items, please issue a contract either directly to Ray Halcyon \
            or the corporation Lucky Drop Shipyards."
          },
          {
            question: "Plans for nullsec moons?",
            answer: "We'll burn that bridge when we get there."
          },
          {
            question: "When will the Moon Atlas be completed?",
            answer:
              "Depends on funds for more Gaze Survey Probes and time. \
              Hopefully within this decade though."
          },
          {
            question: "Can you please prioritze X system/s?",
            answer: "Yes, for donation of ISK or Probes :p"
          },
          {
            question: "There is broken/missing/incorrect data in X System",
            answer: "Please contact us detailing your findings so it can be corrected asap. \
            You can find the contact form under the Info tab in our navigation menu. \
            Alternatively, find us on discord or ingame. An eve mail to Ray Halcyon or \
            Gadsen Heydt is highly appreciated."
          }
        ]
      };
    },
    methods: {
      toggle(index) {
        this.items[index].show = !this.items[index].show;
      }
    }
  };
</script>
  
<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}


.accordion {
  padding-inline: 1vw;

  &__item {
    margin-block: 1rem;
    font-size: 1rem;
  }
  &__content {
    display: grid;
    grid-template-rows: 0fr;
    
    transition: grid-template-rows 400ms ease-in-out;
    &--text { overflow: hidden; transition: padding 400ms ease-in-out; }

    &.show {
      grid-template-rows: 1fr;
      .accordion__content--text { padding: 1rem 2rem; } 
    }

  }
  &__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: start;
    gap: 1rem;

    font-size: 1.1rem;
    padding: 0.5rem 1.5rem;
    width: 100%;

    background: var(--_body-base, --base_100);
    border-color: var(--_body-midtone, --base_300);
    border-radius: .5rem;

    transition: background 350ms ease-in-out;

    &--indicator {
      font-size: 1.8rem;
      font-weight: 700;
      transition: transform 350ms ease-in-out;
    }

    &:hover { background: var(--_body-midtone, --base_300); }
    &.shown { 
      background: var(--_body-midtone, --base_300); 
      .accordion__button--indicator { transform: rotate(-135deg); }
    }

    @include mobile {
      padding: .5rem 1rem;
    }
  }
}
</style>