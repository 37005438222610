<template>

    <div class="home">

      
      <div class="nebula-paint-effect header--height"/>
      <div class="header--wrap">
          <h1 class="header">WebTools for <br> Eve Online</h1>
          <p class="subheader">by FusionRift</p>
          <div class="header--discord">
            <button class="discord-button" @click.prevent="openDiscordLink()">
              <img src="@/assets/icon/discord-logo-white.svg" alt="">
              Join Server
            </button>
        </div>
      </div>


      <ToolsBanner id="tools-banner" />

      <div class="nebula-paint-effect header--band" />
      <!-- <AdSpace /> -->

      <div class="" id="welcome-section">

          <div class="" id="intro-block">
              <h3>Introduction</h3>
              <p>
                Welcome to FusionRift Webtools, the culmination of countless hours of dedication 
                and a passion for providing value. My mission is to provide intuitive and 
                user-friendly third-party tools for the icelandic space MMORPG Eve Online by CCP Games, 
                all accessible at no cost.
              </p><p>
                Over the past six months, I've been meticulously refining the UI/UX to enhance 
                your experience. I believe in the power of aesthetic functionality and intuitive design, 
                and this redesigned interface reflects these beliefs - especially when it comes 
                to the core of this project: The Moon Atlas.
              </p><p>
                I'm committed to constinuously improve this website and constantly update and improve it
                based on user feedback. If you like what you see, join our community discord linked below
                or contact me via ingame mail. I always like to hear thoughts and ideas.
              </p>
          </div>

          <div class="" id="cta-block">
              <div class="cta--text">
                  <h3>Connect with us!</h3>
                  <p>Join our Community Server <a href="https://discord.com/invite/3km5UFkcFP" target="_blank">-> Click here!</a></p>
                  <p>or leave a message via contact form! <router-link :to="{ name: 'contact' }">here!</router-link></p>
              </div>
          </div>

          <DiscordWidget id="discord-widget" />

          <picture id="nebula-bg">
              <source media="(width < 900px)" srcset="@/assets/background/teal-nebula2.webp">
              <img src="@/assets/background/nebula.webp" aria-hidden="true">
          </picture>


    </div>


      <!-- <AdSpace /> -->


    <div class="panel__section">
        
        <!-- <div class="panel__section--block">
          <h4>Changes & Updates</h4>
          <div class="position__block--wrap">
          <router-view v-slot="{ Component, route }" v-if="true">
              <transition :name="route.meta.transition">
                  <component 
                      :is="Component"
                      />
              </transition>
          </router-view>
          </div>
        </div> -->


        <!-- <div class="panel__section--block"> -->
          <!-- <FrequentlyAsked id="faq" /> -->
        <!-- </div> -->

      </div>

    </div>
    
</template>

<script>
import FrequentlyAsked from '@/components/FrequentlyAsked.vue'
import ToolsWall from '@/components/TheToolsWall.vue'
import ToolsBanner from '@/components/TheToolsBanner.vue'
import DiscordWidget from '@/components/TheDiscordWidget.vue'
import AdSpace from '@/components/TheAdspace.vue'

export default {
  name: 'Overview',
  components: {
    FrequentlyAsked, ToolsWall, ToolsBanner, DiscordWidget, AdSpace
  },
  data() {
    return {

    }
  },
  methods: {
    openDiscordLink() {
      const link = 'https://discord.gg/3km5UFkcFP'
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}
@mixin notebook {
  @media only screen and (width < 1250px) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (width < 1650px) {
    @content;
  }
}

h2 {
  font-family: var(--header_font);
  font-size: 1.8em;
  font-weight: 400;
}
h3, h4 {
  font-family: var(--header_font);
  font-size: 1.5em;
  font-weight: 400;
}

#overview-intro {
  position: relative;
  overflow: hidden;
  background: var(--base_300);
  .solotextsegment {
    z-index: 2;
    background: transparent;
    padding-block: 4rem;
    padding-inline-end: 30vw;
    @include tablet { padding-inline: clamp(1rem, 10%, 20vw); }
    filter: drop-shadow(1px 1px 2px black) 
            drop-shadow(1px 1px 1px black);
    p { margin-block-start: 1rem; line-height: 150%; }
  }
  .nebula {
    z-index: 1;
    position: absolute;
    top: -50%;
    right: 0;
    height: 200%;
    mix-blend-mode: soft-light;
  }
}


.nebula-paint-effect {
    width: 100%;
    background: linear-gradient(to right, 
                var(--glass_100), 
                var(--glass_700),
                var(--glass_100) 99%);
    background: url('@/assets/background/red-nebula2.webp'); background-size: 100%;
    backdrop-filter: blur(14px); mix-blend-mode: exclusion; isolation: isolate;
}


#welcome-section {

  display: grid;
  column-gap: 7.5vw;
  padding-inline: 12.5vw;

  grid-template-areas: 
  "cta discord"
  "intro discord";

  #intro-block { grid-area: intro; }
  #cta-block { grid-area: cta; }
  #discord-widget { grid-area: discord; }

  position: relative;
  background: black;
  overflow: hidden;

  
  @include tablet { 
    display: flex;
    flex-flow: column-reverse;
    gap: 4rem;
    padding-inline: 1rem;
    padding-block: 5rem 6rem;
  }

  #intro-block, #cta-block {
    z-index: 6;
    display: grid;
    align-content: center;
    gap: 1rem;
    padding-block: 1rem;
    padding-inline: 7.5vw 10vw;

    background: var(--glass_300);
    mix-blend-mode: luminosity;
    @include tablet {
      border-radius: .5rem;
      margin: 0;
    }
  }

  #cta-block {
    gap: 1rem;
  }
  #intro-block {
    gap: 1.5rem;
  }

  #nebula-bg {
    z-index: 5;
    position: absolute;
    top: 0; right: 0;
    height: 100%;
    
    transition: transform 500ms;

    img { height: 100%; user-select: none; }

    @include tablet { 
      height: 100%;
      object-position: center center;
      object-fit: cover;
     }
  }
}

.home {

  --_header-height: max(36vh, 330px);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  &__section {
    display: flex;
    width: 100%;
    justify-content: center;

    @include tablet {
      flex-direction: column;
    }
    
    &--block {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      width: 50%;
      padding-inline: 1rem;
      padding-block: 4rem;
      background: var(--base_200);
      @include tablet {
        width: 100%;
      }
    }
  }

  .header {
    
    &--height { height: var(--_header-height); }
    &--band { height: 3rem; background-position: bottom; }
    &--discord { 
      text-align: start; 
      @include mobile { 
        padding: 0.25rem 1.5rem; 
        text-align: center; 
        transform: translateY(4vh); 
      }
    }
    &--wrap {
      display: grid;
      align-content: center;
      gap: 1rem;
      position: absolute;
      top: var(--nav-height);
      width: 100%;
      height: var(--_header-height);
      text-align: end;
      padding-inline: 15vw;
    }

    font-family: var(--header_font);
    font-size: clamp(1.8rem, 8vw, 5rem);
    line-height: clamp(1.6rem, 8vw, 4rem);
    font-weight: 900;
    text-wrap: balance;

    background: linear-gradient(to right, #00ffb7 60%, #6b08f7);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    filter: drop-shadow(6px 4px 0px black);
    @include mobile { filter: drop-shadow(3px 2px 0px black); }
  }
  .subheader { 
    font-weight: 600; 
    font-size: clamp(0.75rem, 4vw, 1.4rem);
    color: #fff;
    filter: drop-shadow(3px 2px 0px black);
  }
  
  button.discord-button {
        border-radius: .35rem;
        background: #5865F2;
        border-color: #5865F2;
        font-family: var(--header_font);
        letter-spacing: 3px;
        color: var(--font_color);
        font-size: 1.05rem;
        font-size: clamp(0.65rem, 3.25vw, 1.1rem);
        padding: .5rem 2.5rem;
        text-align: end;
        filter: drop-shadow(-4px 3px 0px black);
        transition: filter 350ms ease-in,
                    background 400ms ease-in-out;
        &:hover {
          background: #828dff;
          filter: drop-shadow(-4px 1px 5px black);
        }
    }

  .fancy-log {
    display: flex;
    flex-flow: wrap;
    gap: 1.5rem;
    padding-block: 1rem;
    margin-block: auto;
    margin-inline: 1rem;
    @include notebook { margin-inline-end: 0; }
    @include tablet { margin-inline: 0; }

    .box { 
      flex-basis: calc(50% - 1rem); 
      @include notebook { flex-basis: 100%; }
    }
    .pane { flex-basis: 100%; }
    .box, .pane {
      display: flex;
      flex-flow: column wrap;
      gap: 1rem;
      
      padding: 1.5rem;
      background: var(--base_100);
      border-radius: 0.5rem;
      border: 2px solid var(--base_300);
      @include tablet { flex-basis: 100%; }
    }
    h6 {
      font-size: 1.1em;
      font-family: var(--header_font);
      font-weight: 600;
    }
    p {
      white-space: wrap;
      font-size: 1em;
      font-weight: 400;
      &::before { position: relative; content: '=>  '; }
    }
    .nolist {
      font-weight: 200;
      &::before { content: unset; }
    }
    .sublist {
      font-weight: 200;
      margin-inline-start: .5em;
      &::before { position: relative; content: '->  '; }
    }
    .date {
      display: block;
      margin-inline-start: 1.2em;
      font-weight: 100;
      font-style: italic;
    }
    .links {
      display: flex;
      gap: 1rem;
    }
  }
  
  

  button { white-space: wrap; }
  p { font-weight: 200; }
  strong { font-weight: 400; }
}
</style>

