<template>
<div class="tool__banner">


    <div class="tool__banner--list">
        <div v-for="(tool, index) in tools" 
            class="tool"
            :class="{'focus': index === current}"
            @click="$router.push({ name: tool.slug })"
            @mouseenter="current = index"
            @focusin="current = index"
            >
            <img class="tool__image--logo" :src="toolImgSrc('icon', tool.logo)" :alt="tool.name" >
            <img class="tool__image" :src="toolImgSrc('background', tool.bg)" :alt="tool.name">
            
            <h2 class="tool__text tool__text--header" v-html="tool.name" />
            <router-link class="tool__text tool__text--link" 
                :to="'/' + tool.slug"
                aria-hidden="true" tabindex="-1"
                >
                <img class="tool__image--goto" :src="toolImgSrc('icon', 'goto_arrow.svg')" :alt="'Go to ' + tool.name" >
              </router-link>
        </div>
    </div>

    <div class="tool__banner--menu">

        <div  v-for="(tool, index) in tools" 
            class="tool__item"
            :class="{'focus': index === current}"
            @mouseenter="current = index"
            @focusin="current = index"
            >
            <img class="tool__item--logo" :src="toolImgSrc('icon', tool.logo)" :alt="tool.name" >
            <h3 class="tool__text tool__item--header" v-html="tool.name" />
        </div>

    </div>

    <div class="tool__banner--info">

        <div class="infobox--binder">
            <div  v-for="(tool, index) in tools"
                class="infobox" :class="{'view': index === current}">
                <div class="infobox--content">
                    <h2 class="tool__text tool__text--header" v-html="tool.name" />
                    <p class="tool__text tool__text--description" v-html="tool.text" />
                    
                    <button 
                        class="tool__button"
                        @focusin="current = index"
                        @click="$router.push({ name: tool.slug })"
                        >
                        Go to {{ tool.name }}
                        <img class="tool__button--goto" :src="toolImgSrc('icon', 'goto_arrow.svg')" :alt="'Go to ' + tool.name" >
                    </button>
                    
                </div>
            </div>
            <img class="infobox--bg" :src="toolImgSrc('background', tools[current].bg)" alt="" aria-hidden="true">
        </div>

    </div>

    <video class="tool__banner--video" 
        autoplay muted loop playsinline 
        aria-hidden="true" 
        :poster="toolImgSrc('background', 'khanidmoon.webp')">
        <source 
            :src="toolImgSrc('background', 'EVEclip.mp4')"
            type="video/mp4">
    </video>

</div>
</template>

<script>
export default {
  name: 'ToolsWall',
  data() {
    return {
      current: 0,
      tools: [
        {
          name: 'Lowsec Moon Atlas',
          slug: 'moons',
          logo: 'moon_circle.svg',
          bg: 'card_atlas.webp',
          text: 'The Eve Online Lowsec Moon Atlas Project, or Moon Atlas for short, \
          is a comprehensive List of Moons in the MMORPG Eve Online. It \
          provides free and public access to Moon Data Scans, Moon Ore, \
          Asteroid percentages and locations in New Eden. Our Moon database has over \
          12 thousand completed Moon Scans and is steadily growing. \
          For now we focus on low security space, but once that is done we will work \
          on null security space and eventually might even scan anoikis too.'
        },
        {
          name: 'Planetary Helper',
          slug: 'planets',
          logo: 'planet_circle.svg',
          bg: 'card_colony2.webp',
          text: 'The Planetary Helper, or PI Helper, \
          is a handy tool for optimizing your Planetary Interaction activities in the \
          MMORPG Eve Online. It helps you choose which PI Materials to produce based \
          on the types of planets you have access to. Our PI Helper is very \
          easy to use and has very clear outputs making it ideal for beginners \
          as well as lazy folks. You can compare different production chains to \
          better plan your extraction and manufacturing schedules. For now we focus \
          on a simplistic interface that makes setting up your PI less of a pain.',
          text_alternative: 'The Eve Online Planetary Interaction Helper, or PI Helper for short, is a handy tool for optimizing your Planetary Interaction activities in the MMORPG Eve Online. It helps you choose which PI Materials to produce based on the types of planets you have access to and the market prices of the materials. Our PI Helper is very easy to use and has very clear outputs. You can compare different production chains, see the profit margins, and plan your extraction and manufacturing schedules. For now we focus on a simplistic interface that makes setting up your PI less of a pain.'
        },
        {
          name: 'Alliance Logos',
          slug: 'logos',
          logo: 'planet_circle.svg',
          bg: 'card_cane.webp',
          text: 'The Alliance Logos service is your go-to resource for getting a custom logo \
          tailored to your alliance or corporation in the MMORPG Eve Online. Whether \
          you’re a fledgling alliance or a seasoned corporation, our skilled designers \
          will craft unique and memorable logos that reflect your identity. From sleek \
          and professional to bold and eye-catching, we’ve got you covered. Stand out \
          among the stars with an emblem that resonates with your members and allies.',
        },
        {
          name: 'Guides and Tutorials',
          slug: 'guides',
          logo: 'planet_circle.svg',
          bg: 'card_colony1.webp',
          text: 'New to the universe of Eve Online? Look no further than our Guides \
          and Tutorials section! Designed specifically for newbros (new players), these \
          concise and straightforward guides provide essential knowledge to kickstart \
          your interstellar journey. Learn the ropes, understand game mechanics, and \
          master the basics—from ship fittings to navigating through treacherous space. \
          Our commitment is to empower you with the information you need to thrive in \
          New Eden. Welcome aboard! ',
        }
      ]
    }
  },
  methods: {
    toolImgSrc(folder, file) {
      return require(`@/assets/${folder}/${file}`)
    }
  }
}
</script>

<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}

.infobox {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 400ms ease-in-out;
  &.view { grid-template-rows: 1fr; }

  &--content {
    display: grid;
    gap: 1.5rem;
    overflow: hidden;
    @include mobile { gap: .5rem; }
  }
  &--binder { 
    position: relative;
    border-radius: .5rem;
    overflow: hidden;
    margin: auto 0; 
    padding: 2rem; 
    backdrop-filter: blur(64px);
    @include mobile { padding: 1rem; }
  }
  &--bg {
    z-index: -1;
    position: absolute;
    top: 0; right: 0;
    user-select: none;
    
    width: 100%;
    height: 100%;

    object-fit: cover;
    filter: brightness(0.1);
    mask-image: linear-gradient(to left, #000 10%, #0000);
    @include tablet { mask-image: linear-gradient(to top, #000 10%, #0000); }
  }
}

.tool {

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  background: #111e;
  border-radius: .5rem;
  padding: 1rem;

  position: relative;
  overflow: hidden;

  cursor: pointer;

  transition: background 430ms ease-in;

  &:hover, &:focus-visible, &:focus-within, &.focus {
    background: #1115;
    .tool__image { object-position: 0 35%; }
    .tool__image--goto { opacity: 1; }
    .tool__text--header { background: #111c; }
  }


  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    
    gap: 1rem;
    position: relative;
    overflow: hidden;
    
    padding: 1.5rem; padding: 1rem 1.5rem;
    margin: .5rem;

    border-radius: .25rem;

    background: #111a;
    background-blend-mode: overlay;
    transition: background 630ms ease-out;

    &.focus { background: #111e; }
    &--header {
      font-size: clamp(0.6rem, 4vw, 1.2rem);
    }
    &--logo {
      height: 3rem;
      aspect-ratio: 1;

      position: absolute;
      bottom: -2rem; left: -1rem;
      height: 10rem;
      z-index: -1;
      filter: brightness(0);

      @include mobile { height: 8rem; }
    }
  }

  &__text {
    &--header {
      font-size: clamp(0.95rem, 5vw, 1.4rem);
      border-radius: .5rem;
      padding: .5rem 1.5rem;
      background: #1116;
      transition: background 330ms ease-in-out;
    }
    &--description {
      font-size: clamp(0.5rem, 3vw, 1rem);
    }
  }

  &__image {

    z-index: -1;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 65%;

    transition: object-position 550ms ease-in-out;

    &--logo {
      height: 5rem;
      aspect-ratio: 1;
    }
    &--goto {
      height: 3rem;
      opacity: .05;
      transition: opacity 440ms ease-out;
    }
  }

  &__banner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #3339;
    position: relative;
    height: 75vh;

    @include tablet {
      height: unset;
      display: flex;
      flex-direction: column;
    }

    &--info {
      z-index: 10;
      display: flex;
      position: relative;
      height: 100%;
      overflow: hidden;

      background: linear-gradient(to right, transparent, #111c);
    }

    &--list {
      z-index: 10;
      display: grid;
      gap: 2rem;
      padding: 2rem;
      @include tablet { display: none; visibility: collapse; }
    }
    &--menu {
      display: none; visibility: collapse; 
      z-index: 10;
      cursor: pointer;

      @include tablet { 
        visibility: visible;
        display: grid;
        // grid-template-columns: 1fr 1fr;
        // grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &--video {
      position: absolute; inset: 0;
      width: 100vw;
      height: 100%;
      user-select: none;
      object-fit: cover;
      overflow: hidden;
    }

    .tool__button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      font-family: var(--header_font);
      letter-spacing: 2px;
      font-size: clamp(0.8rem, 4vw, 1.3rem);
      margin: 2rem 1rem 0;
      padding: 1rem;

      background-image: url('@/assets/background/red-nebula.webp');
      background-attachment: fixed;
      background-position: right 60%;
      background-size: cover;

      background-blend-mode: overlay;
      background-color: #1115;

      border-radius: .5rem;
      border-color: var(--frost_500);

      transition: background 440ms ease-in;

      &:hover, &:focus-visible {
        background-position: right 70%;
        background-color: #111e;
        outline: none;
        border-color: var(--font_color);
      }
      &--goto { height: 1.4em; }

      @include mobile { margin: 1rem 0; }
    }
  }
}

</style>