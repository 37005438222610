<template>
<div class="toolcard__wall">

    <div v-for="tool in tools" 
        class="toolcard"
        :class="{'atlas': tool.slug === 'moons'}"
        @click="$router.push({ name: tool.slug })"
        >
        <img class="toolcard--image" :src="toolImgSrc('background', tool.bg)" :alt="tool.name">
        <div class="toolcard__shade toolcard--image" />
        <img  :src="toolImgSrc('icon', tool.logo)" :alt="tool.name" class="toolcard--logo">
        <h2 class="toolcard__text toolcard--header" v-html="tool.name" />
        <p class="toolcard__text toolcard--description" v-html="tool.text" />
        <router-link class="toolcard__text toolcard--link" :to="'/' + tool.slug">-> Take a Look</router-link>
    </div>

</div>
</template>

<script>
export default {
  name: 'ToolsWall',
  data() {
    return {
      tools: [
        {
          name: 'Lowsec Moon Atlas',
          slug: 'moons',
          logo: 'moon_circle.svg',
          bg: 'card_atlas.webp',
          text: 'The Eve Online Lowsec Moon Atlas Project, or Moon Atlas for short, \
          is a comprehensive List of Moons in the MMORPG Eve Online. It \
          provides free and public access to Moon Data Scans, Moon Ore, \
          Asteroid percentages and locations in New Eden. Our Moon database has over \
          12 thousand completed Moon Scans and is steadily growing. \
          For now we focus on low security space, but once that is done we will work \
          on null security space and eventually might even scan anoikis too.'
        },
        {
          name: 'Planetary Interaction Helper',
          slug: 'planets',
          logo: 'planet_circle.svg',
          bg: 'card_colony2.webp',
          text: 'The Eve Online Planetary Interaction Helper, or PI Helper for short, \
          is a handy tool for optimizing your Planetary Interaction activities in the \
          MMORPG Eve Online. It helps you choose which PI Materials to produce based \
          on the types of planets you have access to. Our PI Helper is very \
          easy to use and has very clear outputs making it ideal for beginners \
          as well as lazy folks. You can compare different production chains to \
          better plan your extraction and manufacturing schedules. For now we focus \
          on a simplistic interface that makes setting up your PI less of a pain.',
          text_alternative: 'The Eve Online Planetary Interaction Helper, or PI Helper for short, is a handy tool for optimizing your Planetary Interaction activities in the MMORPG Eve Online. It helps you choose which PI Materials to produce based on the types of planets you have access to and the market prices of the materials. Our PI Helper is very easy to use and has very clear outputs. You can compare different production chains, see the profit margins, and plan your extraction and manufacturing schedules. For now we focus on a simplistic interface that makes setting up your PI less of a pain.'
        },
        // {
        //   name: 'Planetary Interaction Helper',
        //   slug: 'planets',
        //   logo: 'planet_circle.svg',
        //   bg: 'card_interface.webp',
        //   text: 'The Eve Online Planetary Interaction Helper, or PI Helper for short, \
        //   is a handy tool for optimizing your Planetary Interaction activities in the \
        //   MMORPG Eve Online. It helps you choose which PI Materials to produce based \
        //   on the types of planets you have access to. Our PI Helper is very \
        //   easy to use and has very clear outputs making it ideal for beginners \
        //   as well as lazy folks. You can compare different production chains to \
        //   better plan your extraction and manufacturing schedules. For now we focus \
        //   on a simplistic interface that makes setting up your PI less of a pain.',
        //   text_alternative: 'The Eve Online Planetary Interaction Helper, or PI Helper for short, is a handy tool for optimizing your Planetary Interaction activities in the MMORPG Eve Online. It helps you choose which PI Materials to produce based on the types of planets you have access to and the market prices of the materials. Our PI Helper is very easy to use and has very clear outputs. You can compare different production chains, see the profit margins, and plan your extraction and manufacturing schedules. For now we focus on a simplistic interface that makes setting up your PI less of a pain.'
        // }
      ]
    }
  },
  methods: {
    toolImgSrc(folder, file) {
      return require(`@/assets/${folder}/${file}`)
    }
  }
}
</script>

<style lang="scss">
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 900px) {
    @content;
  }
}


.toolcard {
    --_tool-height: 25vh;
    --_tool-width: 30vw;

    height: var(--_tool-height);
    min-height: 250px;
    flex-grow: 1;
    flex-basis: 500px;
    cursor: pointer;

    // &.atlas { flex-basis: 100%; }

  @include mobile { min-height: 320px; }

    border-radius: .5rem;
    overflow: hidden;

    display: grid;
    grid-template-columns: 1fr 3fr 10fr;
    grid-template-rows: repeat(2, 1fr) 5fr 1fr;
    
    &--logo { 
      grid-area: 1 / 1 / 2 / 2;
      transform-origin: top left;
      transform: translate(1rem, 1rem);
      transition: transform 350ms ease;
      filter: drop-shadow(-2px 2px 0 black);
    }
    &--header { 
      grid-area: 2 / 3 / 3 / 4;
      transform: translateY(calc(var(--_tool-height) / 2.5 ));
      transition: transform 350ms ease;
      filter: drop-shadow(2px 2px 0 black);
    }
    &--description {
      grid-area: 3 / 2 / 5 / 4;
      margin-block-start: 1rem;
      opacity: 0;
      transition: opacity 200ms ease;

      position: relative;
      line-height: 1.25em;
      max-height: calc(1.25em * 4);
      overflow: hidden;
      &::after {
        content: "...";
        position: absolute;
        width: 3em;
        bottom: 0;
        right: 0;
        background: linear-gradient(to left, black 1.5em, transparent);
      }
    }
    &--link {
      grid-area: 4 / 3 / 5 / 4;
      opacity: 0;
      text-decoration: none;
      color: var(--font-color);
      transform-origin: bottom center;
      transition: opacity 230ms ease, font-size 250ms ease-in, color 300ms ease-in-out;
      &:hover, &:focus-visible { text-decoration: underline; color: var(--color_lite); outline: none;}
    }

    &--image {
      grid-area: 1 / 1 / 5 / 4;
      height: 100%;
      width: 100%;
      // object-position: bottom;
      object-fit: cover;
      transition: object-position 450ms ease;
    }
    &:nth-child(even) {
      .toolcard--image { object-position: bottom; }
      &:hover .toolcard--image { object-position: top; }
    }
    &:nth-child(odd) {
      .toolcard--image { object-position: top; }
      &:hover .toolcard--image { object-position: bottom; }
    }
    &__text {
      text-align: end;
      margin-inline: 2rem;
    }
    &__shade {
      background-image: linear-gradient(to right, transparent, black);
      background-position: left;
      background-size: 500% 110%;
      transition: background-position 600ms ease-in-out;
    }

    &:hover, &:focus-within {
      .toolcard--header { transform: translateY(0); }
      .toolcard--description { opacity: 1; transition: opacity 200ms ease 350ms; }
      .toolcard--logo { transform: translate(1rem, 1rem) scale(1.5); }
      .toolcard--link { opacity: 1; font-size: large; }
      .toolcard__shade { background-position: right; }
    }
    &:focus-within { 
      outline: 2px solid var(--accent_lite);
      outline-offset: 3px;
     }


    &__wall {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      gap: 2rem;
      background: var(--glass_700);
      padding-inline: 10vw;
      padding-block: max(15vh,3rem);

      @include mobile {
        gap: 4rem;
        padding-inline: 1rem;
        padding-block: 10rem;
        background: var(--glass_100);
      }
    }

  }
</style>