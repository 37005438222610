<template>

  <nav>
    <TheLogo />
    <TheNavigation />
  </nav>

  <main>
    <router-view/>
    <div class="footer">
      © 2023 FusionRift |  
      All Eve Online related Materials are property of 
      <a target="_blank" href="https://www.ccpgames.com/">CCP Games</a>
    </div>
  </main>


  <img src="./assets/background/metropolismoon.webp" class="background">

</template>

<script>
import TheLogo from '@/components/TheLogo.vue';
import TheNavigation from '@/components/TheNavigation.vue';

export default {
  components: {
    TheLogo, TheNavigation
  }
}
</script>

<style lang="scss">
@mixin tiny {
  @media only screen and (width < 400px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (width < 600px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (width < 990px) {
    @content;
  }
}
@mixin laptop {
  @media only screen and (width < 1200px) {
    @content;
  }
}

:root {
  --nav-height: 3rem;
  --header_font: 'Montserrat', sans-serif;
  --font: 'Inter', sans-serif;
  --font_color: #f5f5f5;

  --base_100: #0a0a0a;
  --base_200: #121212;
  --base_300: #232323;
  --base_400: #2b2b2b;
  --base_500: #3e3e3e;
  --base_800: #5e5e5e;
  --base_900: #7c7c7c;

  --glass_100: #121212ee;
  --glass_300: #121212cd;
  --glass_500: #12121299;
  --glass_700: #12121250;

  --frost_100: #ffffffac;
  --frost_500: #fff5;
  --frost_900: #fff1;

  --color_dark: #045;
  --color_darker: #021b22;
  --color_darkglass: #023845cc;
  --color_darkerglass: #021b22ee;
  --color_bold: #267;
  --color_boldglass: #267c;
  --color_base: #3a9;
  --color_baseglass: #3a9c;
  --color_lite: #0fa;
  --color_liteglass: #0fac;
  
  --accent_dark: #620;
  --accent_darkglass: #620c;
  --accent_bold: #940;
  --accent_boldglass: #940c;
  --accent_base: #c60;
  --accent_baseglass: #c60c;
  --accent_lite: #f90;
  --accent_liteglass: #f90c;

  
}
*, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
    scroll-behavior: smooth;

    /* FIREFOX scrollbar */
    scrollbar-width: thin;
    scrollbar-color: var(--color_lite) var(--base_100); /*thumb | track*/
}
/* other scrollbars */
*::-webkit-scrollbar{
    height: .5rem; width: .5rem;
}
*::-webkit-scrollbar-track {
    width: 0; height: 0;
    background-color: var(--base_100);
    border-radius: .5rem;
}
*::-webkit-scrollbar-thumb {
    background: var(--color_lite);
    border-radius: .5rem;
}

/* text highlight color when selected with cursor */
::selection {
  background: var(--color_liteglass);
  color: var(--color_lite);
  text-shadow:  0 0 1px var(--color_lite), 
                0 0 2px var(--color_darker), 
                0 0 3px var(--color_darker),
                0 0 5px var(--color_darker),
                0 0 7px var(--color_darker)
                ;
  filter: none;
}

:target {
  scroll-margin-top: 6rem; /* Your fixed header's height */
}


// Transitions for child components
.position--wrap { 
  position: relative; 
  min-height: 100vh; 
  // min-width: calc(100vw - 5rem);
}
.position__block--wrap {
  position: relative;
  min-height: 100vh;
}
// SLIDE
.slide-left-enter-active, 
.slide-right-enter-active, 
.slide-left-leave-active, 
.slide-right-leave-active {
  position: absolute; inset: 0;
  transition: transform 350ms ease-in-out;
}
.slide-left-enter { transform: translateX(100%); }
.slide-right-enter { transform: translateX(-100%); }

.slide-left-leave { transform: translateX(100%); }
.slide-right-leave { transform: translateX(-100%); }

.slide-left-leave-to { transform: translateX(-100%); }
.slide-right-leave-to { transform: translateX(100%); }

.slide-left-enter-from { transform: translateX(100%); }
.slide-right-enter-from { transform: translateX(-100%); }
.slide-left-enter-to { transform: translateX(0); }
.slide-right-enter-to { transform: translateX(0); }
// FADE
.fade-enter-active, .fade-leave-active {
  position: absolute; inset: 0;
  transition: opacity 350ms ease-in-out;
}
.fade-enter-from, .fade-leave-to { opacity: 0; }
.fade-enter-to, .fade-leave-from { opacity: 1; }
// CUBE
.cube-open-enter-active, 
.cube-close-enter-active, 
.cube-open-leave-active, 
.cube-close-leave-active {
  position: absolute; inset: 0;
  transform-origin: center left;
  transition: transform 370ms ease-in-out, opacity 320ms ease-in-out;
}
.cube-open-enter, .cube-close-enter,
.cube-open-leave, .cube-close-leave,
.cube-open-leave-to, .cube-close-leave-to,
.cube-open-enter-from, .cube-close-enter-from { transform: rotateY(90deg); opacity: 0; }
.cube-open-enter-to, .cube-close-enter-to { transform: rotateY(0); opacity: 1; }



@keyframes spinning {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(360deg); }
}

@keyframes dscan {
  0% { background-position: 0%; }
  100% { background-position: 100%; }
}

.loading--dscan {
    font-size: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to left, 
              var(--color_darkerglass), 
              var(--color_darkglass),
              var(--color_darkglass), 
              var(--color_baseglass), 
              var(--color_liteglass), 
              var(--color_baseglass), 
              var(--color_darkerglass)
              );
    background-size: 400% 100%;
    background-position-x: 0%;
    padding-block: 15vh;
    backdrop-filter: blur(13px);
    animation: dscan alternate infinite 4s ease-in-out;
  }
.wait {
  height: 1em; width: 1em;
  color: transparent;
  border: .1em var(--glass_300) solid;
  border-left-color: var(--accent_lite, crimson);
  border-radius: 50%;
  animation: spinning 1500ms linear infinite;
}

a {
  color: var(--color_base);
  transition: color 250ms ease;
  &:visited {color: var(--color_bold);}
  &:hover {color: var(--color_lite);}
  &.accent {
    color: var(--accent_base);
    &:visited {color: var(--accent_bold);}
    &:hover {color: var(--accent_lite);}
  }
}

strong {
  font-weight: normal;
  transition: color 250ms;
}
.card.view strong {
  color: var(--color_lite);
}

#app {
  overflow: hidden;
  font-family: var(--font, Arial);
  color: var(--font_color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  .background {
    height: 100vh;
    width: 100vw;
    position: fixed;
    inset: 0;
    object-fit: cover;
    z-index: -1;
  }
}


main {
  overflow-y: auto;
  overflow-x: hidden;

  position: absolute;
  inset: 0;

  height: 100vh;
  width: 100vw;
  padding-block-start: 3rem;

  background: transparent;

  .footer {
    padding-block: 2rem;
    text-align: center;
    font-size: small;
    font-weight: 200;
    background: var(--glass_700);
    backdrop-filter: blur(150px);
    a {font-weight: 300;}
  }

}

button {
    color: inherit;
    border: 2px solid var(--font_color);
    border-radius: 2rem;
    padding: .5rem 1rem;
    white-space: nowrap;
    cursor: pointer;
    &:focus-visible {
      outline: 2px solid var(--accent_lite);
      outline-offset: 3px;
      text-decoration: underline;
      text-underline-offset: 4px;
    }
    &:disabled {
        cursor: default;
    }
  }

nav {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  top: 0; left: 0;
  z-index: 100;

  width: 100%;
  height: var(--nav-height);
  padding-inline: 5rem;

  background: var(--base_100);

  @include tiny {
    padding-inline: 1rem;
  }
}

.content {

  &--wrap { 
    --_content-header: max(40vh, 650px);
    --_content-width: 1200px;

    &[theme="dark"] {
      --_body-base: var(--base_100);
      --_body-midtone: var(--base_200);
      --_body-litetone: var(--base_300);
      --_font: var(--font_color);
    }
    &[theme="light"] {
      --_body-base: #c5c5c5;
      --_body-midtone: var(--base_800);
      --_body-litetone: #a7a7a7;
      --_font: var(--base_100);
    }

    @include laptop { --_content-width: 100vw; }
    @include tablet { --_content-header: 30rem; }
    @include mobile { --_content-header: 20rem; }

      
    .themetoggle {
      input { display: none; visibility: collapse; }
      position: relative;
      height: 1.30rem; width: 2.25rem;
      background: var(--_font);
      border: 1px solid var(--_body-midtone);
      border-radius: 1rem;
      &::before {
        content: '';
        position: absolute;
        top: .15rem; left: .15rem;
        height: 1rem; width: 1rem;
        background: var(--_body-base);
        border-radius: 1rem;
        transition: transform 500ms ease;
      }
      &::after {
        content: '';
        height: 1rem; width: 1rem;
        top: .15rem; left: .65rem;
        border-radius: 1rem;
        position: absolute;
        background: var(--_font);
        transition: transform 230ms ease-out;
      }
      &.light::before {
        transform: translateX(.9rem);
      }
      &.light::after {
        transform: scale(0);
      }
    }
  }

  &__header {
    
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-inline: calc((100% - var(--_content-width)) / 2);
    margin-block-end: calc(var(--_content-header) / 2);
    @include laptop { margin-inline: 2rem; }

    &--title {
      font-family: var(--header_font);
      font-size: clamp(1.4rem, 5vw, 3.5rem);
      font-weight: 600;
      letter-spacing: 4px;
      text-wrap: balance;
      filter: drop-shadow(5px 3px 0px black);
      @include mobile { filter: drop-shadow(3px 2px 0px black); }
    }
    &--subtitle {
      font-family: var(--header_font);
      font-size: clamp(0.9rem, 3vw, 1.5rem);
      font-weight: 400;
      letter-spacing: 4px;
      text-wrap: balance;
    }
    &--box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      height: var(--_content-header);
      background-color: var(--base_800);
      // background: url('@/assets/background/red-nebula.webp');
      background-size: cover;
      background-attachment: fixed;
      background-blend-mode: overlay;

      overflow: hidden;
      margin-block-end: calc(var(--_content-header) / 2 * -1); // this creates the overlap!!
    }
    &--shadow {
      position: absolute; inset: 0;
      width: 100%; height: 100%;
      background: linear-gradient(to bottom, transparent 50%, var(--_body-midtone));
      // margin-bottom: calc(var(--_content-header) / 2 * -1);
    }
  }
  &__body {
    
    display: flex;
    display: grid;
    gap: 2rem;
    position: relative;
    width: 100%;
    padding-inline: calc((100% - var(--_content-width)) / 2);
    @include laptop { padding-inline: 1rem; gap: 1rem; }

    grid-template-areas: 
    "box box side"
    "box box side"
    "box box side"
    ;
    &.noside {
      grid-template-areas: 
      "box box"
      "box box"
      "box box"
      ;
    }

    &--wrap { 
      display: grid;
      justify-items: center;
      min-height: 100vh;
      background: var(--_body-midtone);
      background-image: linear-gradient(to bottom, var(--_body-midtone) 50%, var(--_body-base) calc(100% - 10rem));
      transition: background-image 460ms ease-in-out;
    }

    &--box { 
      display: flex;
      background: var(--_body-base);
      grid-area: box;
      transition: background 320ms ease-in-out;
    }
    &--controls {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-items: flex-start;
      gap: 1rem;
      width: 4rem;
      min-width: 4rem;
      min-height: 100vh;
      padding-block: 1.5rem;
      margin-block-end: 1rem;
      border-right: 1px solid var(--_body-litetone);

      @keyframes Copy {
        0% { background-color: var(--_body-base); }
        10% { background-color: var(--color_lite); }
        100% { background-color: var(--_body-base); }
      }

      .share {
        display: grid; place-items: center;
        position: relative; overflow: hidden;
        height: 2.5rem; width: 2.5rem;
        padding: 0;
        background: var(--_body-midtone);
        border-radius: 10rem;
        border-color: var(--_body-litetone);
        transition: background 360ms ease-in-out, border-color 360ms ease;

        img { height: 1.4rem; }
        &:hover { background: var(--_body-litetone); }
        &::after {
          content: '';
          position: absolute; top: -2px; left: -2px;
          height: inherit; width: inherit;
          border-radius: 10rem;
          background: var(--color_lite);
          mix-blend-mode: multiply;
          opacity: 0;
          transition: opacity 750ms ease-out;
        }
        &:active { 
          border-color: var(--color_lite);
          &::after { 
            opacity: 1;
            transition: opacity 360ms ease; 
          }
        }
      }
    }
    &--text {
      color: var(--_font);
      margin: 2.5rem 1.5rem;
      // width: 100%;
    }
    &--sidebar { 
      display: flex;
      flex-flow: column nowrap;
      gap: 1.5rem;
      width: 30ch; 
      grid-area: side;
    }
    &--sideitem {
      // width: 30ch;
      // max-width: 30ch;
      padding: 1rem;
      background: var(--_body-base);
    }
  }

}
</style>
