<template>

    <iframe class="discord"
        src="https://discord.com/widget?id=906329477502627860&theme=dark" 
        width="350" 
        height="500" 
        allowtransparency="true" 
        frameborder="0" 
        sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
        >
    </iframe>

</template>

<style lang="scss">
.discord {
    z-index: 10;
    margin-block: 2rem;
    mix-blend-mode: luminosity;
}
</style>