import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'FusionRift\'s Eve Online WebTools Overview'
    },
    // redirect: {
    //   name: 'home.overview'
    // },
    // children: [
    //   {
    //     path: '',
    //     name: 'home.overview',
    //     component: () => import(/* webpackChunkName: "home.overview" */ '../views/HomeOverviewView.vue'),
    //     meta: {
    //       title: 'FusionRift\'s WebTools for Eve Online - Overview'
    //     }
    //   },
      // {
      //   path: 'updates',
      //   name: 'home.updates',
      //   component: () => import(/* webpackChunkName: "home.updates" */ '../views/HomeUpdatesView.vue'),
      //   meta: {
      //     title: 'FusionRift Webtools Updates'
      //   }
      // },
      // {
      //   path: 'roadmap',
      //   name: 'home.roadmap',
      //   component: () => import(/* webpackChunkName: "home.roadmap" */ '../views/HomeRoadmapView.vue'),
      //   meta: {
      //     title: 'FusionRift Webtools Roadmap'
      //   }
      // },
    // ]
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    meta: {
      title: 'FusionRift\'s Webtools - Contact'
    },
  },
  {
    path: '/updates',
    name: 'updates',
    component: () => import(/* webpackChunkName: "updates" */ '../views/UpdatesView.vue'),
    meta: {
      title: 'FusionRift\'s Webtools - Updates'
    },
  },
  {
    path: '/roadmap',
    name: 'roadmap',
    component: () => import(/* webpackChunkName: "roadmap" */ '../views/RoadmapView.vue'),
    meta: {
      title: 'FusionRift\'s Webtools - Roadmap'
    },
  },
  {
    path: '/frequently-asked-questions',
    name: 'faq',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FrequentlyAskedView.vue'),
    meta: {
      title: 'FusionRift\'s Webtools - F.A.Q.'
    },
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: () => import(/* webpackChunkName: "impressum" */ '../views/ImpressumView.vue'),
    meta: {
      title: 'FusionRift\'s Webtools - Impressum'
    },
  },
  {
    path: '/logos',
    name: 'logos',
    component: () => import(/* webpackChunkName: "logos" */ '../views/LogosView.vue'),
    meta: {
      title: 'FusionRift\'s Webtools - Alliance Logos'
    },
  },
  {
    path: '/moons',
    name: 'moons',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "moons" */ '../views/MoonView.vue'),
    meta: {
      title: 'Eve Online Lowsec Moon Database'
    },
    redirect: {
      name: 'moon.status'
    },
    children: [
      {
        path: '',
        name: 'moon.status',
        component: () => import(/* webpackChunkName: "moon.status" */ '../views/MoonStatusView.vue'),
        meta: {
          title: 'Eve Online Moon Atlas - Status'
        }
      },
      {
        path: 'table',
        name: 'moon.table',
        component: () => import(/* webpackChunkName: "moon.status" */ '../views/MoonDataView.vue'),
        meta: {
          title: 'Eve Online Moon Atlas - Database Status'
        }
      },
      {
        path: 'system/:locVal?',
        name: 'moon.system',
        component: () => import(/* webpackChunkName: "moon.system" */ '../views/MoonSystemView.vue'),
        meta: {
          title: 'Eve Online Moon Atlas - System'
        }
      },
      {
        path: 'constellation/:locVal?',
        name: 'moon.constellation',
        component: () => import(/* webpackChunkName: "moon.constellation" */ '../views/MoonConstellationView.vue'),
        meta: {
          title: 'Eve Online Moon Atlas - Constellation'
        }
      },
      {
        path: 'region/:locVal?',
        name: 'moon.region',
        component: () => import(/* webpackChunkName: "moon.region" */ '../views/MoonRegionView.vue'),
        meta: {
          title: 'Eve Online Moon Atlas - Region'
        }
      }
    ]
  },
  {
    path: '/guides',
    name: 'guides',
    component: () => import(/* webpackChunkName: "guides" */ '../views/GuidesView.vue'),
    meta: {
      title: 'FusionRift\'s Webtools - Guides & Articles'
    },
  },
  {
    path: '/planets',
    name: 'planets',
    component: () => import(/* webpackChunkName: "planets" */ '../views/PlanetView.vue'),
    meta: {
      title: 'Eve Online Planetary Interaction Helper'
    }
  }

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // tabname
  document.title = 'FusionRift • ' + to.meta.title || 'FusionRift • WebTools';
  
  // transitions
  const fromRoute = from.name ? from.name.split('.').pop() : null;
  const toRoute = to.name ? to.name.split('.').pop() : null;

  if (to.name.startsWith('home')) { // home 'changes & updates' section
    const isFade = (fromRoute !== 'overview' && toRoute !== 'overview') && (['updates', 'roadmap', 'contact'].includes(fromRoute) && ['updates', 'roadmap', 'contact'].includes(toRoute));
    const isCubeOpen = fromRoute === 'overview' && ['updates', 'roadmap', 'contact'].includes(toRoute);
    const isCubeClose = toRoute === 'overview' && ['updates', 'roadmap', 'contact'].includes(fromRoute);

    to.meta.transition = isFade ? 'fade' : isCubeOpen ? 'cube-open' : isCubeClose ? 'cube-close' : 'fade';

  } else if (to.name.startsWith('moon')) { // moon atlas tabs
    const isFade = (fromRoute === 'status' && toRoute === 'table') || (fromRoute === 'table' && toRoute === 'status');
    const isSlideRight = (fromRoute === 'region' && ['system', 'constellation'].includes(toRoute)) || (fromRoute === 'constellation' && toRoute === 'system') || (['status', 'table'].includes(fromRoute) && ['region', 'constellation', 'system'].includes(toRoute));

    to.meta.transition = isFade ? 'fade' : isSlideRight ? 'slide-right' : 'slide-left';

  } else {
    to.meta.transition = 'fade'
  }

  // console.log(`Navigating from ${from.fullPath} to ${to.fullPath} with transition ${to.meta.transition}`);
  next();
});



export default router
