<template>
    <div class="adspace">

    </div>
</template>

<style>
.adspace {
    width: 100%;
    height: 10rem;
    display: grid;
    place-items: center;
}
</style>